import React, { useState } from 'react';
import './App.css';


function App() {


  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-200 min-h-screen flex flex-col">
      <header className="bg-blue-600 py-8">
        <div className="container mx-auto px-4">
          <h1 className="text-white text-5xl font-semibold text-center mb-4">UserVal</h1>
          <p className="text-white text-lg text-center">A platform for startup founders to validate their ideas</p>
        </div>
      </header>
      <section className=" w-full  text-center bg-[url(./images/interview.jpg)] bg-cover">
        <div className='bg-slate-300 py-12 bg-opacity-30'>
          <h2 className="text-4xl font-bold mb-8">How It Works</h2>
          <p className="text-xl text-gray-800 leading-relaxed max-w-3xl mx-auto font-semibold">
            UserVal simplifies the process of idea validation for startup founders. Submit your idea, conduct user interviews, and refine your concept based on valuable feedback.
          </p>
          <div className="flex flex-row max-[600px]:flex-col max-[600px]:items-center justify-center mb-8 mt-8 text-white gap-8">
            <div className='bg-blue-600 h-[300px] flex flex-col items-center w-[200px] gap-2 rounded-xl p-4 '>
              <h3 className='text-[28px] font-bold'>Step 1</h3>
              <p className='text-[24px] text-center text-slate-300'>Founder submits an idea they want to validate</p>
            </div>
            <div className='bg-blue-600 h-[300px] flex flex-col items-center w-[200px] gap-2 rounded-xl p-4 '>
              <h3 className='text-[28px] font-bold'>Step 2</h3>
              <p className='text-[24px] text-center text-slate-300'>Founder submits a questionnaire to select suitable users.</p>
            </div>
            <div className='bg-blue-600 h-[300px] flex flex-col items-center w-[200px] gap-2 rounded-xl p-4 '>
              <h3 className='text-[28px] font-bold'>Step 3</h3>
              <p className='text-[24px] text-center text-slate-300'>Founder interviews users on their opinion on the idea.</p>
            </div>
            <div className='bg-blue-600 h-[300px] flex flex-col items-center w-[200px] gap-2 rounded-xl p-4 '>
              <h3 className='text-[28px] font-bold'>Step 4</h3>
              <p className='text-[24px] text-center text-slate-300'>User gets paid for the interview.</p>
            </div>
          </div>
          <div className=" py-12">
            <div className="container mx-auto text-center">
              <h2 className="text-3xl font-bold mb-8">Sign-up Now to be Notified When<br /> the App is Fully Launched!</h2>
              <form action="https://api.web3forms.com/submit" method='POST' className="flex flex-col gap-4 items-center justify-center">
                <input type="hidden" name="access_key" value="d11905d8-53d8-4d61-b19d-c6b3e3c35b3b" />
                <input
                  name='email'
                  type="email"
                  placeholder="Enter your email"
                  required
                  className="px-8 py-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-64 bg-gray-200 text-gray-8001"
                />
                <label htmlFor='role' className='font-semibold text-[24px]'>Are you a founder or a user?</label>
                <input
                  name='role'
                  required
                  type="text"
                  placeholder="Founder/User"
                  className="px-8 py-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-64 bg-gray-200 text-gray-8001"
                />

                <button type="submit" className="bg-blue-600 text-white px-12 py-4 rounded-full hover:bg-blue-700 w-64 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">Sign Up</button>
              </form>
              <script src="https://web3forms.com/client/script.js" async defer></script>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-gray-900 py-4 mt-auto">
        <div className="container mx-auto text-white text-center">
          <p>Contact us: uservalcontact@gmail.com</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
